<!-- 绑定班级 -->
<template>
  <div>
    <div style="height: 3rem"></div>
    <div class="box_css">
      <div class="title_css">绑定班级</div>
      <div class="form_css">
        <el-select v-model="value" filterable placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.ClassID"
            :label="item.className"
            :value="item.ClassID"
          >
          </el-option>
        </el-select>
        <div class="LogOut_css" @click="ConfirmSave()">确定保存</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getMyClass
} from "@/api/index.js";
export default {
  data() {
    return {
      options: [],
      value: "",
    };
  },
  created(){
    getMyClass({
		GathererID: 1
	}).then(res =>{
    console.log(res)
		this.options = res.data
    this.value = localStorage.getItem('classID') || ''
	})
  },
  methods: {
    // 确定保存
    ConfirmSave() {
        if(this.value){
            localStorage.setItem('classID', this.value)
            let name = ''
            for(let i=0;i<this.options.length;i++){
                if(this.options[i].ClassID == this.value){
                    name = this.options[i].className
                    break
                }
            }
            localStorage.setItem('className', name)
            this.$bus.$emit('getclassName')
            this.$message.success("班级保存成功");

            let token = localStorage.getItem('smartCampusToken')
            if(token){
              this.$router.push({ path: '/' })
            }else{
              this.$router.push({ path: '/scanCode' })
            }
        }else{
            this.$message.error("请选择班级");
        }
    },
  },
};
</script>
<style scoped>
.box_css {
  width: 1200px;
  height: calc(100vh - 230px);
  margin: 0 auto;
  background-color: #ffffffda;
  border-radius: 25px;
}
.title_css {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding: 40px 0;
}
.form_css {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.name_css {
  font-size: 30px;
  font-weight: bold;
  margin: 15px 0 150px;
}
.LogOut_css {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 450px;
  margin-top: 320px;
  background-color: #59a6f6;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
  cursor: pointer;
}
/deep/ .el-input__inner{
    width: 450px;
    padding: 30px 0 30px 15px;
    font-size: 24px;
}
</style>